<template>
  <div class="MultipleMonitoring">
    <refreshTime ref="refreshTime"></refreshTime>
    <div class="search-box">
      <div>
        学校筛选：
        <el-select
          v-model="schoolId"
          placeholder=""
          filterable
          @change="selectChange"
        >
          <el-option label="全部" value="-1"> </el-option>
          <el-option
            v-for="item in schoolList"
            :key="item.schoolId"
            :label="item.schoolName"
            :value="item.schoolId"
          >
          </el-option>
        </el-select>
        题目：
        <el-select
          v-model="questionNum"
          placeholder="请选择"
          @change="checkQuestion"
        >
          <el-option
            v-for="item in multipleConditionData"
            :key="item.questionNum"
            :label="item.questionNum"
            :value="item.questionNum"
          >
          </el-option>
        </el-select>
        教师打分差大于：
        <el-input
          v-model="score"
          placeholder=""
          @keyup.native="score = handleOninput(score, '3')"
        ></el-input>
        分（该题误差分：{{ indexQuestionObj.errorScore }}分，满分
        {{ indexQuestionObj.score }}分）
        <el-button type="primary" plain @click="multipleList">搜索</el-button>
      </div>
      <el-button
        type="primary"
        plain
        :disabled="questionNum === ''"
        :loading="btnLoading"
        @click="exportFile()"
      >
        导出搜索结果
      </el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="multipleListData"
      border
      style="width: 100%"
    >
      <el-table-column prop="studentExamId" label="学生考号" align="center">
      </el-table-column>
      <el-table-column prop="studentName" label="姓名" align="center">
      </el-table-column>
      <el-table-column prop="address" label="一评打分" align="center">
        <template slot-scope="scope">
          {{ scope.row.score }}分 （{{
            scope.row.teacherName + "," + scope.row.schoolName
          }}）
        </template>
      </el-table-column>
      <el-table-column prop="address" label="二评打分" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.score2 || scope.row.score2 == 0">
            {{ scope.row.score2 }}分 （{{
              scope.row.teacherName2 + "," + scope.row.schoolName2
            }}）
          </template>
          <template v-else> - </template>
        </template>
      </el-table-column>
      <template v-if="indexQuestionObj.ruleScore == 3">
        <el-table-column prop="address" label="三评打分" align="center">
          <template slot-scope="scope">
            <template v-if="scope.row.score3 || scope.row.score3 == 0">
              {{ scope.row.score3 }}分 （{{
                scope.row.teacherName3 + "," + scope.row.schoolName3
              }}）
            </template>
            <template v-else> - </template>
            <!-- {{ scope.row.score3 }}分 （{{ scope.row.teacherName3 }}） -->
          </template>
        </el-table-column>
      </template>

      <el-table-column prop="address" label="查看" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="showPaper(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getObj, schoollistbyexamid } from "@/core/api/exam/exam";
import {
  multipleCondition,
  multipleList,
  multipleExport,
} from "@/core/api/exam/paperMonitor";
import { fileDownloadByUrl, showSubject } from "@/core/util/util";
import { getStore } from "@/core/util/store";
import refreshTime from "./components/refreshTime";
export default {
  name: "MultipleMonitoring",
  components: { refreshTime },
  data() {
    return {
      btnLoading: false,
      listLoading: false,
      pageIndex: 1,
      pageSize: 50,
      total: 0,
      paperList: [],
      multipleConditionData: [],
      multipleListData: [],
      questionNum: "",
      indexQuestionObj: {},
      examPaperId: "",
      score: "",
      userInfo: {},
      schoolList: [],
      schoolId: "-1",
    };
  },

  created() {
    this.examPaperId = Number(this.$route.query.examPaperId);
    if (this.$route.query.markType == 2) {
      return;
    }
    this.getExamInfo();
    this.multipleCondition();
    this.getSchoollistbyexamid();
    this.userInfo = getStore({ name: "userInfo" });
  },
  methods: {
    getNewItem() {
      this.multipleCondition();
    },
    async getSchoollistbyexamid() {
      const res = await schoollistbyexamid({
        examId: this.$route.query.examId,
      });

      this.schoolList = res.data.data;
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.multipleList();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.multipleList();
      // console.log(`当前页: ${val}`);
    },
    selectChange() {
      this.multipleCondition();
    },
    // 只能输入数字和小数点
    handleOninput(value, type) {
      let str = value;
      let len1 = str.substr(0, 1);
      let len2 = str.substr(1, 1);
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1);
      }
      //第一位不能是.
      if (len1 == ".") {
        str = "";
      }
      if (len1 == "+") {
        str = "";
      }
      if (len1 == "-") {
        str = "";
      }

      //限制只能输入一个小数点
      if (str.indexOf(".") != -1) {
        let str_ = str.substr(str.indexOf(".") + 1);
        if (str_.indexOf(".") != -1) {
          str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
        }
      }
      //正则替换
      str = str.replace(/[^\d^\\.]+/g, ""); // 保留数字和小数点
      if (type == "3") {
        str = str.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/, "$1"); // 小数点后只能输 3 位
      } else {
        str = str.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, "$1"); // 小数点后只能输 2 位
      }
      return str;
    },
    showSubject(v) {
      return showSubject(v, 8);
    },
    exportFile() {
      let data = {
        examPaperId: this.examPaperId,
        questionNum: this.questionNum,
        score: this.score || 0,
        sheetDetailId: this.indexQuestionObj.sheetDetailId,
      };
      let name = this.examInformation.name + "多评监控.xlsx";
      this.btnLoading = true;
      multipleExport(data)
        .then((res) => {
          // console.log(res);
          fileDownloadByUrl(res.data.data.url, name);
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    showPaper(item) {
      if (!item.answerUrl) {
        this.$message({
          message: "当前试题没有答题卡图片！",
          type: "warning",
        });
        return;
      }
      let data = {};
      data.examPaperId = this.examPaperId;
      data.questionNum = this.questionNum;
      data.studentId = item.studentId;
      data.errorScore = this.score;
      //  = item;
      let Base64 = require("js-base64").Base64;

      const routeData = this.$router.resolve({
        name: "ShowPaperAnswer",
        query: {
          code: Base64.encode(JSON.stringify(data)),
        },
      });
      window.open(routeData.href, "_blank");
      // console.log(item.sheetUrl);
    },
    multipleList() {
      if (this.questionNum === "") {
        return;
      }
      let data = {
        examPaperId: this.examPaperId,
        score: this.score || 0,
        questionNum: this.questionNum,
        current: this.pageIndex,
        size: this.pageSize,
        schoolId: this.schoolId,
        sheetDetailId: this.indexQuestionObj.sheetDetailId,
      };

      this.listLoading = true;
      multipleList(data)
        .then((res) => {
          // this.multipleListData = res.data.data;
          this.listLoading = false;
          this.multipleListData = res.data.data.records;
          this.total = res.data.data.total;
          // console.log(res);
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    //**  **//
    getExamInfo() {
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
        res.data.data.paperList.map((item) => {
          this.paperList.push({
            value: item.paperId,
            label: item.subjectName,
            subjectId: item.subjectId,
          });
        });
      });
    },
    checkQuestion() {
      this.indexQuestionObj = this.multipleConditionData.filter(
        (item) => item.questionNum == this.questionNum
      )[0];
      this.score = this.indexQuestionObj.errorScore;
      this.multipleList();
    },
    checkSubject() {
      this.multipleCondition();
    },
    multipleCondition() {
      let data = {
        examPaperId: this.examPaperId,
      };
      multipleCondition(data).then((res) => {
        // console.log(res);
        if (res.data.data.length > 0) {
          this.multipleConditionData = res.data.data;
          this.questionNum = this.multipleConditionData[0].questionNum;
          this.score = this.multipleConditionData[0].errorScore;
          this.indexQuestionObj = this.multipleConditionData[0];
          this.multipleList();
        } else {
          this.multipleConditionData = [];
          this.questionNum = "";
          this.indexQuestionObj = {};
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.MultipleMonitoring {
  padding: 24px;
  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .el-input {
      width: 80px;
    }
    .el-select {
      margin: 0 24px 0 8px;
    }
    .subject-name {
      width: 120px;
    }
  }
}
</style>
